import React, { FC } from "react";
import styled, { css } from "styled-components";
import Button from "./Button";

interface Props {
  prevId: string;
  nextId: string;
  between?: boolean;
  full?: boolean;
  isSlider?: boolean;
  onNextClick?: any;
  onPrevClick?: any;
}

const Arrows: FC<Props> = ({
  prevId,
  nextId,
  children,
  between,
  full,
  isSlider,
  onNextClick,
  onPrevClick,
  ...props
}) => {
  return (
    <Wrapper between={between} full={full} {...props}>
      {!isSlider && <PrevArrow id={prevId} onClick={onPrevClick} />}
      {children}
      <NextArrow id={nextId} isSlider={isSlider} onClick={onNextClick} />
    </Wrapper>
  );
};

interface WrapperProps {
  between?: boolean;
  full?: boolean;
}

interface NextArrowProps {
  isSlider?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ between }) => (between ? "space-between" : "initial")};
  width: ${({ full }) => (full ? "100%" : "auto")};
`;

const ArrowEl = styled(Button)<NextArrowProps>`
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    display: block;
    background: ${({ isSlider }) => {
        return isSlider
          ? "url(/static/icons/arrow-white.svg)"
          : "url(/static/icons/prev.svg)";
      }}
      no-repeat center;
    content: "";
  }
`;

const PrevArrow = styled(ArrowEl)`
  margin-right: 12px;

  & + button {
    margin-left: 0;
  }
`;

const GreenArrow = css`
  background: #78b833;
  box-shadow: 0px 12px 22px rgba(128, 168, 18, 0.813887);
  border-radius: 10px;
  height: 60px;
  width: 60px;
`;

const NextArrow = styled(ArrowEl)<NextArrowProps>`
  margin-left: 12px;
  ${({ isSlider }) => isSlider && GreenArrow};

  &:after {
    transform: rotate(180deg);
  }
`;

export default Arrows;
