import gql from "graphql-tag";

export const GET_SALES_DATA = gql`
  query getSales($slug: String!) {
    shop {
      id
      navigations(where: { slug: $slug }) {
        id
        lineItems(where: { active: true }, orderBy: sort_ASC) {
          id
          name
          sort
          description
          slug
          tags
          metafields {
            id
            description
            namespace
            value
            values
            key
            files {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_SALE_DETAIL = gql`
  query getDetailSale($slug: String!, $slugSale: String!) {
    shop {
      id
      navigations(where: { slug: $slug }) {
        id
        lineItems(orderBy: sort_ASC, where: { slug: $slugSale }) {
          id
          name
          description
          tags
          metafields {
            value
            key
            files {
              url
            }
          }
        }
      }
    }
  }
`;
