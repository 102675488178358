import React, { FC } from "react";
import styled from "styled-components";
import Link from "next/link";
import { BtnHover } from "./Button";
import Image from "./Image";
import { cover } from "../styles/_fit-images";

interface Props {
  slug?: string;
  name: string;
  image: any;
  width?: number;
  height?: number;
}
const CategoryItem: FC<Props> = ({
  slug,
  name,
  image,
  width = 400,
  height = 300,
  ...props
}) => {
  let href = "/catalog";
  let mask = "/catalog";

  if (slug) {
    href = `/catalog/${slug}`;
    mask = "/catalog/[code]";
  }

  const src = image && image.url;

  return (
    <Link href={mask} as={href}>
      <Wrapper href={href} {...props}>
        <CustomImage width={width} height={height} src={src} alt="" />
        <Content>
          <Name>{name}</Name>
        </Content>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.a`
  z-index: 1;
  position: relative;
  display: inline-block;
  background: #fff;
  overflow: hidden;
  border-radius: 15px;
  ${BtnHover};
  box-shadow: 0px 22px 44px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 22px 44px rgba(0, 0, 0, 0.15);
  }

  &:active {
    box-shadow: 0px 22px 44px rgba(0, 0, 0, 0.05);
  }

  &:before {
    display: block;
    width: 100%;
    padding-bottom: 88.88%;
    content: "";
  }

  &:after {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 3.3%,
      #ffffff 98.24%
    );
    border-radius: 14px;

    content: "";
  }
`;

const CustomImage = styled(Image)`
  && {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    display: block;
    width: 100%;
    height: 100%;
    ${cover};
  }
`;

const Content = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding: 20px;
`;

const Name = styled.h3`
  font-size: 28px;
`;

export default CategoryItem;
