import React from "react";
import styled from "styled-components";
import Slider from "../components/main/Slider";
import Categories from "../components/main/Categories";

import dynamic from "next/dynamic";
import { GREEN } from "../components/styles/constants";

const Popular = dynamic(import("../components/main/Popular"), {
  ssr: false,
});
const PromoData = dynamic(import("../components/main/PromoData"), {
  ssr: false,
});
const NewData = dynamic(import("../components/main/NewData"), {
  ssr: false,
});

const Page = () => {
  return (
    <Container>
      <Slider />
      <Categories />

      <GreenLoader>
        <PromoData />
      </GreenLoader>
      <NewData />
      <Popular />
    </Container>
  );
};

Page.getInitialProps = async () => {
  return {
    main: true,
  };
};

const Container = styled.div``;

const GreenLoader = styled.div`
  background-color: ${GREEN};
  min-height: 80vh;
`;

export default Page;
