import React, { FC, ReactElement } from "react";
import ReactIdSwiper from "react-id-swiper/lib/ReactIdSwiper.custom";
import { Swiper, Mousewheel } from "swiper/js/swiper.esm";
import styled from "styled-components";

interface Props {
  children: ReactElement[];
  space?: number;
}
const OverflowScroll: FC<Props> = ({ children, space, ...props }) => {
  const swiperProps = {
    Swiper,
    modules: [Mousewheel],
    slidesPerView: "auto",
    freeMode: true,
    mousewheel: true,
    spaceBetween: space || 0,
    threshold: 3,
  };

  return (
    <Container space={space} {...props}>
      <ReactIdSwiper {...swiperProps}>
        {children.map((el, index) => (
          <Slide key={index}>{el}</Slide>
        ))}
      </ReactIdSwiper>
    </Container>
  );
};

interface ContainerProps {
  space?: number;
}
const Container = styled.div<ContainerProps>`
  .swiper-container {
    overflow: visible;
    &:not(.swiper-container-initialized) {
      .swiper-slide:not(:last-child) {
        margin-right: ${({ space }) => (space ? `${space}px` : 0)};
      }
    }
  }
  .swiper-wrapper {
    box-sizing: border-box;
  }
`;

const Slide = styled.div`
  width: auto;
`;

export default OverflowScroll;
