import React, { FC } from "react";
import MaxWidth from "../styles/max-width";
import styled from "styled-components";
import CategoryItem from "../common/CategoryItem";
import { GREEN } from "../styles/constants";
import OverflowScroll from "../common/OverflowScroll";
import { Query } from "react-apollo";
import getUndef from "../../lib/get-undef";
import { GET_COLLECTIONS } from "../../queries/Collections";
import { getCollections } from "../../__generated__/getCollections";

const Categories: FC<{}> = () => {
  return (
    <Wrapper>
      <MaxWidth>
        <Query<getCollections>
          query={GET_COLLECTIONS}
          variables={{
            where: {
              metafields_some: {
                key: "hideCategory",
                value_not: "Да",
              },
            },
          }}
        >
          {({ loading, error, data }: any) => {
            if (loading || error) {
              return null;
            }
            const collections = getUndef(() => data.shop.collections);

            return (
              <div>
                <OverflowScrollCats space={15}>
                  {collections
                    .filter(
                      ({ metafields }) =>
                        !metafields.find(
                          ({ key, value }) =>
                            key === "hideCategory" && value === "Да"
                        )
                    )
                    .map((i) => (
                      <Item width={200} height={178} key={i.name} {...i} />
                    ))}
                </OverflowScrollCats>
                <MobileRow>
                  {collections
                    .filter(
                      ({ metafields }) =>
                        !metafields.find(
                          ({ key, value }) =>
                            key === "hideCategory" && value === "Да"
                        )
                    )
                    .map((i) => {
                      i.id += "mobile";
                      return <Item key={i.name} {...i} />;
                    })}
                </MobileRow>
              </div>
            );
          }}
        </Query>
      </MaxWidth>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding: 20px 0 50px;
  &:after {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 140px;
    background-color: ${GREEN};
    content: "";
  }

  @media (max-width: 600px) {
    padding-top: 20px;
  }
`;

const OverflowScrollCats = styled(OverflowScroll)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px -20px 0;

  @media (min-width: 601px) {
    display: none;
  }
`;

const Item = styled(CategoryItem)`
  width: 200px;

  @media (max-width: 600px) {
    width: auto;
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 0 20px 20px 0;
  }
`;

export default Categories;
