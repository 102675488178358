import gql from "graphql-tag";

export const GET_COLLECTIONS = gql`
  query getCollections($where: CollectionWhereInput) {
    shop {
      id
      collections(where: $where, orderBy: sort_ASC) {
        id
        slug
        name
        sort
        image {
          id
          url
        }
        metafields {
          key
          namespace
          value
          description
          files {
            id
            url
          }
        }
      }
    }
  }
`;
