import React, { FC, useState, memo } from "react";
import styled from "styled-components";
import { SwiperInstance } from "react-id-swiper";
import ReactIdSwiper from "react-id-swiper/lib/ReactIdSwiper.custom";
import { Swiper, Autoplay, Pagination, EffectFade } from "swiper/js/swiper.esm";
import Arrows from "../common/Arrows";
import MaxWidth from "../styles/max-width";
import Link from "next/link";
import { Query } from "react-apollo";
import getUndef from "../../lib/get-undef";
import { GET_SALES_DATA } from "../../queries/Sales";
import { coverTop } from "../styles/_fit-images";
import Image from "../common/Image";

const Slider: FC<{}> = () => {
  const [swiper, setSwiper] = useState<SwiperInstance>(null);

  return (
    <CustomMaxWidth>
      <SwiperWrapper>
        <Query
          query={GET_SALES_DATA}
          variables={{
            slug: "promo",
          }}
        >
          {({ loading, error, data }: any) => {
            if (error || loading) {
              return null;
            }

            const salesItems = getUndef(
              () => data.shop.navigations[0].lineItems
            );

            const swiperConfig = {
              Swiper,
              modules: [Autoplay, Pagination, EffectFade],
              preloadImages: false,
              slidesPerView: 1,
              effect: "fade",
              autoplay: {
                delay: 4000,
                disableOnInteraction: false,
              },
              pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
                renderBullet: function (index, className) {
                  const url =
                    salesItems[index].metafields[0].files[0] &&
                    salesItems[index].metafields[0].files[0].url;
                  return `<img src="${process.env.NEXT_PUBLIC_OPTIM_ENDPOINT}/90/60/70/?url=${url}&fit=cover" srcSet="${process.env.NEXT_PUBLIC_OPTIM_ENDPOINT}/180/120/70/?url=${url}&fit=cover 2x" class="${className} cover alt="image"/>`;
                },
              },
              // navigation: {
              //   prevEl: `#${prevId}, #${prevMobileId}`,
              //   nextEl: `#${nextId}, #${nextMobileId}`,
              // },
              threshold: 1,
              getSwiper: setSwiper,
            };

            return (
              <ReactIdSwiper {...swiperConfig}>
                {salesItems.map((i) => {
                  const image = i.metafields.find(
                    ({ value }) => value === "big"
                  );
                  const imageSmall = i.metafields.find(
                    ({ value }) => value === "small"
                  );
                  const link = i.metafields.find(
                    (item) => item.key === "link"
                  )?.value;

                  const src = image.files[0] && image.files[0].url;
                  const srcMobile =
                    imageSmall.files[0] && imageSmall.files[0].url;

                  return (
                    <Slide key={i.slug}>
                      {link ? (
                        <a
                          rel="noopener noreferrer"
                          href={link}
                          target="_blank"
                        >
                          <ImgWrapperMobile>
                            <ImgMobile
                              src={srcMobile}
                              height={500}
                              width={375}
                              position="top"
                            />
                          </ImgWrapperMobile>
                        </a>
                      ) : (
                        <Link href={"/promo/[i.code]"} as={`/promo/${i.slug}`}>
                          <ImgWrapperMobile>
                            <ImgMobile
                              src={srcMobile}
                              height={500}
                              width={375}
                              position="top"
                            />
                          </ImgWrapperMobile>
                        </Link>
                      )}

                      {link ? (
                        <a
                          rel="noopener noreferrer"
                          href={link}
                          target="_blank"
                        >
                          <ImgWrapper>
                            <Img src={src} height={490} width={1350} />
                          </ImgWrapper>
                        </a>
                      ) : (
                        <Link href="/promo/[code]" as={`/promo/${i.slug}`}>
                          <ImgWrapper>
                            <Img src={src} height={490} width={1350} />
                          </ImgWrapper>
                        </Link>
                      )}
                    </Slide>
                  );
                })}
              </ReactIdSwiper>
            );
          }}
        </Query>
        <Arrow
          isSlider={true}
          onNextClick={() => {
            const activeIndex = swiper.activeIndex;
            const lastIndex = swiper.slides.length - 1;
            if (activeIndex !== lastIndex) {
              swiper.slideNext();
            } else {
              swiper.slideTo(0);
            }
          }}
        />
      </SwiperWrapper>
    </CustomMaxWidth>
  );
};

const CustomMaxWidth = styled(MaxWidth)`
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const ImgWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media (max-width: 420px) {
    display: none;
  }
`;

const ImgWrapperMobile = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;

  @media (max-width: 420px) {
    display: block;
  }
`;

const ImgMobile = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Img = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Slide = styled.div`
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    width: 100%;
    padding-bottom: 36.9%;
    content: "";

    @media (max-width: 421px) {
      padding-bottom: 138%;
    }
  }
`;

const SwiperWrapper = styled.div`
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    margin-top: -35px;
  }

  @media (max-width: 420px) {
    margin-top: -25px;
  }

  .swiper-wrapper {
    box-sizing: border-box;
  }

  .swiper-slide {
    border-radius: 30px;
    overflow: hidden;

    @media (max-width: 1200px) {
      border-radius: 0;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      ${coverTop};
      cursor: pointer;
    }
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: flex-end;
    bottom: 27px;
    right: 85px;
    left: auto;
    margin: 0px 5px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .swiper-pagination {
    text-align: inherit;
    img {
      position: relative;
      display: block;
      width: 90px;
      height: 60px;
      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.265993);
      border-radius: 10px;
      opacity: 1;
      transition: 225ms ease-in;

      &:hover {
        transform: translate3d(0, -3px, 0);
        transition: 180ms ease-out;
      }
    }
  }
`;

const Arrow: any = styled(Arrows)`
  z-index: 2;
  position: absolute;
  display: block;
  bottom: 27px;
  right: 20px;

  button {
    &:after {
      transform: inherit;
    }
  }
`;

export default memo(Slider);
